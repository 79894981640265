import React from 'react';
import { OwnUpThemeWrapper } from '@rategravity/own-up-component-library-legacy';
import { breakpoints } from './src/modules/breakpoints';
import {
  FOG_20,
  OwnUpComponentLibraryProvider,
  ownUpLightTheme
} from '@rategravity/own-up-component-library';
import { createTrackingInstance, TrackingProvider } from '@rategravity/1pt-lib';
import * as Sentry from '@sentry/gatsby';
import { createTheme } from '@material-ui/core';

const App = ({ element }) => {
  const isLocal = typeof window !== 'undefined' && !window.location.origin.includes('.ownup.com');

  const trackingInstance = createTrackingInstance({
    debug: isLocal,
    url: '/events',
    local: isLocal
  });

  if (trackingInstance.getAnonymousId()) {
    Sentry.setUser({ id: trackingInstance.getAnonymousId() });
  }

  const theme = createTheme({
    ...ownUpLightTheme,
    palette: {
      ...ownUpLightTheme.palette,
      background: {
        default: FOG_20
      }
    }
  });

  return (
    <OwnUpComponentLibraryProvider theme={theme}>
      <OwnUpThemeWrapper breakpoints={breakpoints} preload_advisors={false}>
        <TrackingProvider value={trackingInstance}>{element}</TrackingProvider>
      </OwnUpThemeWrapper>
    </OwnUpComponentLibraryProvider>
  );
};

export default ({ element }) => <App element={element} />;
